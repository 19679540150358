<template>
  <!-- 优惠劵套餐 -->
  <div class="box-card">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" clearable v-model="queryForm.parking_id" filterable default-first-option
          placeholder="请选择停车场" @change="onSubmit()">
          <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="套餐包：">
        <el-input maxlength="30" v-model="queryForm.coupon_package_name" @keyup.enter.native="onSubmit"
          placeholder="请输入套餐包" @input="(e) => (queryForm.coupon_package_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select popper-class="my-select" clearable v-model="queryForm.status" filterable default-first-option
          placeholder="请选择状态" @change="onSubmit()">
          <el-option v-for="item in getOptions('PackageStatus')" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="优惠劵套餐">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('0d99c2df7bc843b1b6373409')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="showAddCouponCombo = true"
            v-if="this.getUserInfo.user_name != 'admin' && isShowBtn('7347f7efc1c04ef9abe27f63')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div style="text-align:left;">
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="coupon_package_price">
            ￥ {{ row.coupon_package_price.toFixed(2) }}
          </template>
          <template slot-scope="{row}" slot="status">
            <lebo-switch :status="row.status !== 1" :turnOn="'已上架'" :turnOff="'已下架'" :switchType="row.status"
              @switchChange="changeStatus(row)"></lebo-switch>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="warning" icon="bianji" hint="编辑" @click="editCouponCombo(row._id)"
              v-if="row.status != 1 && isShowBtn('742648099bac4a299265b554')"></lbButton>
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookCouponCombo(row._id)" v-if="row.status == 1">
            </lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="coupon_package_name" label="套餐包"></el-table-column>
          <el-table-column prop="coupon_name" label="优惠劵" min-width="150"></el-table-column>
          <el-table-column label="套餐价" width="130">
            <template slot-scope="scope">
              ￥ {{ scope.row.coupon_package_price.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="状态"  v-if='isShowBtn("d2d3c53d146b49a6b384fd96")'>
            <template slot-scope="scope">
              <lebo-switch :status="scope.row.status !== 1" :turnOn="'已上架'" :turnOff="'已下架'"
                :switchType="scope.row.status" @switchChange="changeStatus(scope.row)"></lebo-switch>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" width="140">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editCouponCombo(scope.row._id)"
                v-if="scope.row.status != 1 && isShowBtn('742648099bac4a299265b554')"></lbButton>
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookCouponCombo(scope.row._id)"
                v-if="scope.row.status == 1"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>

    <!-- 新增优惠券套餐 -->
    <lebo-dialog append-to-body title="新增优惠券套餐" :isShow="showAddCouponCombo" width="52%"
      @close="showAddCouponCombo = false" footerSlot>
      <addCouponComboComponent @closeDialog="closeAccountCombo" v-if="showAddCouponCombo"></addCouponComboComponent>
    </lebo-dialog>
    <!-- 编辑优惠券套餐 -->
    <lebo-dialog append-to-body title="编辑优惠券套餐" :isShow="showEditCouponCombo" width="52%"
      @close="showEditCouponCombo = false" footerSlot>
      <addCouponComboComponent @closeDialog="closeAccountCombo" :id="dialogCouponComboId" v-if="showEditCouponCombo">
      </addCouponComboComponent>
    </lebo-dialog>
    <!-- 查看优惠券套餐 -->
    <lebo-dialog append-to-body title="查看优惠券套餐" :isShow="showLookCouponCombo" width="40%"
      @close="showLookCouponCombo = false" footerSlot closeOnClickModal>
      <lookCouponCombo @closeDialog="closeAccountCombo" :id="dialogCouponComboId" v-if="showLookCouponCombo">
      </lookCouponCombo>
    </lebo-dialog>
  </div>
</template>
<script>
import { addCouponCombo, updateCouponCombo, delCouponCombo, setCouponComboStatus, getCouponComboPageList, getCouponComboDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import addCouponComboComponent from './components/addCouponCombo/index.vue'
import lookCouponCombo from './components/lookCouponCombo/index.vue'
export default {
  components: { addCouponComboComponent, lookCouponCombo },
  data () {
    return {
      queryForm: {
        PageSize: 10,
        PageIndex: 1,
        coupon_package_name: '',
        parking_id: '',
        status: null // 状态 1.已上架 2.待上架 3.已下架
      },
      total: 0,
      // 表格数据
      tableData: [],
      // 选中的表格数据
      multipleSelection: [],
      showAddCouponCombo: false, // 新增优惠券套餐
      showEditCouponCombo: false,
      showLookCouponCombo: false,
      dialogCouponComboId: '',
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '套餐包',
            prop: 'coupon_package_name'
          }, {
            label: '优惠劵',
            width: 400,
            prop: 'coupon_name',
            slot: true
          }, {
            label: '套餐价',
            prop: 'coupon_package_price',
            slot: true
          }, {
            label: '状态',
            prop: 'status',
            slot: true
          }, {
            label: '创建时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fngetCouponComboPageList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList', 'getUserInfo'])
  },
  watch: {},
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fngetCouponComboPageList () {
      const res = await getCouponComboPageList({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        parking_id: this.queryForm.parking_id,
        coupon_package_name: this.queryForm.coupon_package_name,
        status: this.queryForm.status ? this.queryForm.status : 0
      })
      this.tableData = res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    businessStateColor (status) {
      //  1.已上架 2.待上架 3.已下架
      switch (status) {
        case 1: return 'color:#67c23a;'
        case 2: return 'color:#e6a23c;'
        case 3: return 'color:#bbb;'
      }
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fngetCouponComboPageList()
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fngetCouponComboPageList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fngetCouponComboPageList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryForm.PageIndex = val
      this.fngetCouponComboPageList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fngetCouponComboPageList()
    // },
    // 批量删除
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的优惠劵套餐, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i]._id)
            if (arr.length === this.multipleSelection.length) {
              obj._id = arr.join(',')
              this.fndelCouponCombo(obj)
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的优惠劵套餐！')
      }
    },
    async fndelCouponCombo (obj) {
      const res = await delCouponCombo(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      };
      this.fngetCouponComboPageList()
    },
    // 上架、下架
    async batchChang (row) {
      console.log(row.status)
      this.$confirm(row.status === 1 ? '是否确定下架该套餐包？' : '是否确定上架该套餐包？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await setCouponComboStatus({
          _id: row._id,
          status: row.status === 1 ? 3 : 1,
          coupon_package_name: row.coupon_package_name
        })
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.fngetCouponComboPageList()
        }
      }).catch(() => {

      })
    },
    async changeStatus (row, type) {
      console.log(row.status)
      this.$confirm(row.status === 1 ? '是否确定下架该套餐包？' : '是否确定上架该套餐包？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await setCouponComboStatus({
          _id: row._id,
          status: row.status === 1 ? 3 : 1,
          coupon_package_name: row.coupon_package_name
        })
        if (res && res.Code === 200) {
          this.$msg.success(res.Message)
          this.fngetCouponComboPageList()
        }
      }).catch(() => {

      })
    },
    closeAccountCombo () {
      this.showAddCouponCombo = false
      this.showEditCouponCombo = false
      this.showLookCouponCombo = false
      this.fngetCouponComboPageList()
    },
    editCouponCombo (id) {
      this.showEditCouponCombo = true
      this.dialogCouponComboId = id
    },
    lookCouponCombo (id) {
      this.dialogCouponComboId = id
      this.showLookCouponCombo = true
    },
    // 选中的数据
    selectionChange (list) {
      this.multipleSelection = list
    }
  }
}
</script>
<style scoped lang="less">
.box-card {
  margin-top: 20px;

  .switch_box {
    display: flex;
    align-items: center;
    cursor: pointer;

    .switch {
      width: 35px;
      height: 18px;
      border-radius: 9px;
      margin-right: 10px;
      background-color: #cccccc;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0, -50%);
      }
    }

    .switch_check {
      width: 35px;
      height: 18px;
      border-radius: 9px;
      margin-right: 10px;
      background-color: #1acd90;
      position: relative;

      &::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        right: 3px;
        transform: translate(0, -50%);
      }
    }
  }
}</style>
