<template>
    <!-- 查看优惠劵套餐 -->
    <div class="box-card" style="text-align: left;">
      <el-form :model="batchAddForm" ref="batchAddFormRef" label-width="120px" class="form_box">
        <el-form-item label="套餐包：">{{ batchAddForm.coupon_package_name }}
        </el-form-item>
        <el-form-item label="停车场：">{{ batchAddForm.parking_name }}
          <!-- <el-select popper-class="my-select" v-model="batchAddForm.parking_id" filterable default-first-option
            placeholder="请选择停车场">
            <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
              :value="item.parking_id">
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="优惠劵：" >
          <div v-for="(item, index) in batchAddForm.coupons" :key="index" style="display:flex;">
            {{item.coupon_name }} {{ item.coupon_price }}元/张 {{ item.coupon_count }}张
          </div>
        </el-form-item>
        <el-form-item label="优惠劵时效：">{{ batchAddForm.valid_day }}  天
        </el-form-item>
        <el-form-item label="套餐价：">{{ batchAddForm.coupon_package_price }}元
        </el-form-item>
      </el-form>
    </div>
  </template>
<script>
import { addCouponCombo, updateCouponCombo, delCouponCombo, getCouponsSelectList, setCouponComboStatus, getCouponComboPageList, getCouponComboDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      batchAddForm: {
        coupon_package_name: '', // 套餐包名称
        coupon_package_price: '',
        parking_id: '',
        coupons: [{
          coupon_id: '', // 优惠券id
          coupon_name: '', // 优惠券名称
          coupon_type: null, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
          coupon_price: null, // 优惠券价格
          coupon_count: null // 优惠券数量
        }],
        valid_day: null, // 优惠券时效
        parking_name: '' // 停车场名字
      },
      couponsSelectList: []
    }
  },
  created () {
    console.log(this.id)
    this.fngetCouponsSelectList()
    if (this.id) this.fngetCouponComboDetails()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fngetCouponComboDetails () {
      const res = await getCouponComboDetails({ _id: this.id })
      console.log(res)
      if (res && res.Code === 200) {
        this.batchAddForm.coupon_package_name = res.Data.coupon_package_name
        this.batchAddForm.parking_id = res.Data.parking_id
        this.batchAddForm.coupon_package_price = res.Data.coupon_package_price
        this.batchAddForm.coupons = res.Data.coupons
        this.batchAddForm.valid_day = res.Data.valid_day
        this.batchAddForm.parking_name = res.Data.parking_name
      }
    },
    async fngetCouponsSelectList () {
      const res = await getCouponsSelectList()
      this.couponsSelectList = res && res.Code === 200 && res.Data ? res.Data : []
    }
  }
}
</script>
  <style scoped lang="less">
  .form_box {
    max-height: 650px;
    overflow: hidden;
    overflow-y: auto;

    /deep/ .el-input {
      width: 350px;
    }

    /deep/ .el-textarea {
      width: 80%;
    }

  }
  </style>
