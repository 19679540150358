<template>
  <!-- 新增优惠劵套餐 -->
  <div class="box-card" style="text-align: left;">
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px" class="form_box">
      <el-form-item label="套餐包：" prop="coupon_package_name">
        <el-input v-model="batchAddForm.coupon_package_name" placeholder="请输入套餐包名称" maxlength="30"
          @input="(e) => (batchAddForm.coupon_package_name = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item label="停车场：" prop="parking_id">
        <el-select popper-class="my-select" v-model="batchAddForm.parking_id" filterable default-first-option
          placeholder="请选择停车场">
          <el-option v-for="item in getAllParkingList()" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="优惠劵：" prop="coupons">
        <div v-for="(item, index) in batchAddForm.coupons" :key="index" style="display:flex;margin-bottom:20px;"
          class="couponsList">
          <el-form-item :prop="`coupons[${index}].coupon_id`"
            :rules="[{ required: true, message: '请选择优惠劵', trigger: 'blur' }]" class="couponsSelect">
            <el-select v-model="item.coupon_id" popper-class="my-select" filterable clearable placeholder="请选择优惠劵"
              @change="couponChange(item.coupon_id, index)">
              <el-option v-for="j in couponsSelectList" :key="j._id" :label="j.coupon_name" :value="j._id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :prop="`coupons[${index}].coupon_price`"
            :rules="[{ required: true, message: '请输入金额', trigger: 'blur' }, { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }]"
            class="NumberInput">
            <el-input v-model="item.coupon_price" placeholder="请输入金额" maxlength="9"
              @input="(e) => (item.coupon_price = validSpacing(e))"></el-input>
          </el-form-item>
          <div style="min-width: 50px;">元/张</div>
          <el-form-item :prop="`coupons[${index}].coupon_count`"
            :rules="[{ required: true, message: '请输入张数', trigger: 'blur' }]" class="NumberInput">
            <el-input v-model="item.coupon_count" placeholder="请输入张数" maxlength="8"
              @input="(e) => (item.coupon_count = validNumberInteger(e))"></el-input>
          </el-form-item>
          <div style="min-width: 5px;">
            张
          </div>
          <el-form-item>
            <el-button style="margin-left:15px;padding:8px!important;" icon="el-icon-plus" size="mini" circle
              @click="addCoupons"></el-button>
            <el-button style="padding:8px!important;" icon="el-icon-minus" size="mini" circle v-if="index > 0"
              @click="delCoupons(index)"></el-button>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="优惠劵时效：" prop="valid_day">
        <el-input v-model="batchAddForm.valid_day" placeholder="请输入优惠劵时效" maxlength="8"
          @input="(e) => (batchAddForm.valid_day = validNumberInteger(e))"></el-input> 天
      </el-form-item>
      <el-form-item label="套餐价：" prop="coupon_package_price">
        <el-input v-model="batchAddForm.coupon_package_price" placeholder="请输入套餐价格" maxlength="9"
          @input="(e) => (batchAddForm.coupon_package_price = validSpacing(e))"></el-input> 元
      </el-form-item>
    </el-form>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <!-- <lbButton class="checkButton2" size="small" type="warning" @click="submitAddForm(2)" v-preventReClick>仅保存</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm(1)" v-preventReClick>保存并上架
    </lbButton>
  </div>
</template>
<script>
import { addCouponCombo, updateCouponCombo, delCouponCombo, getCouponsSelectList, setCouponComboStatus, getCouponComboPageList, getCouponComboDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      batchAddForm: {
        coupon_package_name: '', // 套餐包名称
        coupon_package_price: '',
        parking_id: '',
        coupons: [{
          coupon_id: '', // 优惠券id
          coupon_name: '', // 优惠券名称
          coupon_type: null, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
          coupon_price: null, // 优惠券价格
          coupon_count: null // 优惠券数量
        }],
        valid_day: null // 优惠券时效
      },
      batchAddFormRules: {
        coupon_package_name: [{ required: true, message: '请输入套餐包名称', trigger: 'blur' }],
        parking_id: [{ required: true, message: '请选择停车场', trigger: 'blur' }],
        coupon_package_price: [{ required: true, message: '请输入套餐价', trigger: 'blur' }, { pattern: /^([1-9]\d*|0)(\.\d{1,2})?$/, message: '请输入带两位小数的数字', trigger: 'blur' }],
        coupons: [{ required: true, message: '请选择优惠劵', trigger: 'blur' }],
        valid_day: [{ required: true, message: '请输入优惠劵时效', trigger: 'blur' }]
      },
      couponsSelectList: []
    }
  },
  created () {
    console.log(this.id)
    this.fngetCouponsSelectList()
    if (this.id) this.fngetCouponComboDetails()
  },
  mounted () { },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName', 'getAllParkingList'])
  },
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fngetCouponComboDetails () {
      const res = await getCouponComboDetails({ _id: this.id })
      console.log(res)
      if (res && res.Code === 200) {
        this.batchAddForm.coupon_package_name = res.Data.coupon_package_name
        this.batchAddForm.parking_id = res.Data.parking_id
        this.batchAddForm.coupon_package_price = res.Data.coupon_package_price
        this.batchAddForm.coupons = res.Data.coupons
        this.batchAddForm.valid_day = res.Data.valid_day
      }
    },
    async fngetCouponsSelectList () {
      const res = await getCouponsSelectList()
      this.couponsSelectList = res && res.Code === 200 && res.Data ? res.Data : []
    },
    couponChange (id, index) {
      if (!id) return
      var i = this.couponsSelectList.findIndex(item => item._id === id)
      this.batchAddForm.coupons[index].coupon_name = this.couponsSelectList[i].coupon_name
      this.batchAddForm.coupons[index].coupon_type = this.couponsSelectList[i].coupon_type
    },
    addCoupons () {
      this.batchAddForm.coupons.push({
        coupon_id: '', // 优惠券id
        coupon_name: '', // 优惠券名称
        coupon_type: null, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
        coupon_price: null, // 优惠券价格
        coupon_count: null // 优惠券数量
      })
    },
    delCoupons (index) {
      this.batchAddForm.coupons.splice(index, 1)
    },
    // 保存
    submitAddForm (status) {
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          var obj = {
            coupon_package_name: this.batchAddForm.coupon_package_name,
            coupon_package_price: this.batchAddForm.coupon_package_price,
            parking_id: this.batchAddForm.parking_id,
            coupons: this.batchAddForm.coupons,
            valid_day: this.batchAddForm.valid_day,
            status: status
          }
          if (this.id) {
            obj._id = this.id
            this.fnupdateCouponCombo(obj)
          } else {
            this.fnaddCouponCombo(obj)
          }
        } else {
          return false
        }
      })
    },
    async fnaddCouponCombo (obj) {
      const res = await addCouponCombo(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      };
    },
    async fnupdateCouponCombo (obj) {
      const res = await updateCouponCombo(obj)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    }
  }
}
</script>
<style scoped lang="less">
.form_box {
  max-height: 650px;
  overflow: hidden;
  overflow-y: auto;

  /deep/ .el-input {
    width: 80%;
  }

  /deep/ .el-select {
    width: 100%;
  }

  /deep/ .el-textarea {
    width: 80%;
  }

}

.couponsList {
  width: 100%;
  .couponsSelect {
    width: 25%;

    /deep/.el-select {
      width: 100%;
    }
    /deep/ .el-input {
      width: 100%;
    }
  }

  .NumberInput {
    width: 23%;
    margin: 0 9px;

    /deep/ .el-input {
      width: 100%;
    }
  }

  // .el-form-item__content {
  //   width: 30%;

  //   .el-select {
  //     width: 100%;

  //     /deep/ .el-input {
  //       width: 100%;
  //     }
  //   }

  // }

  // /deep/ .el-input {
  //   width: 80%;
  // }

}

.box-card {
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 10px;
  }

  .checkButton2 {
    position: absolute;
    right: 150px;
  }

  .checkButton3 {
    position: absolute;
    right: 250px;
  }
}</style>
